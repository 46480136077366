import React, { FunctionComponent, useRef, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Close } from "../../images/icons/close";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import Accessibility from "../Accessibility";

import "./styles.scss";

// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";

function getWindowDimensions() {
  let width = 0;
  let height = 0;

  if (typeof window !== "undefined") {
    width = window.innerWidth;
    height = window.innerHeight;
  }

  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const ImageGrid: FunctionComponent = props => {
  const { items, heading, _rawBody } = props;

  const previewPanelRefs = useRef([]);
  const { height, width } = useWindowDimensions();
  const [selectedItem, setSelectedItem] = useState({});
  const [activeItemId, setActiveItemId] = useState("");
  const [cards] = useState([...items]);
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const { _rawImage, name, sport, _rawBio } = selectedItem;

  useEffect(() => {
    const handleResize = () => {
      setSelectedItem({});
      resetImagePanel();
      setActiveItemId("");
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function resetImagePanel(event?: any) {
    if (previewPanelRefs.current.length > 0) {
      handleImagePanel(100, event);
    }
  }

  function handleImagePanel(slideToShow, event) {
    [1, 3, 5, 7].map(item => {
      if (item === slideToShow) {
        if (previewPanelRefs.current[item]) {
          previewPanelRefs.current[item].hidden = false;
        }

        if (typeof window !== "undefined") {
          window.scrollTo(0, event.currentTarget.offsetParent.offsetTop);
        }
      } else {
        previewPanelRefs.current[item] ? (previewPanelRefs.current[item].hidden = true) : null;
      }
    });
  }

  function openItem(event, index) {
    setSelectedItem(items[index]);
    setActiveItemId(event.currentTarget.dataset.id);
    if (previewPanelRefs.current.length > 0) {
      if (width < 768) {
        if (index <= 1) {
          handleImagePanel(1, event);
        } else if (index > 1 && index <= 3) {
          handleImagePanel(3, event);
        } else if (index > 3 && index <= 5) {
          handleImagePanel(5, event);
        } else if (index > 5 && index <= 7) {
          handleImagePanel(7, event);
        }
      } else {
        if (index <= 3) {
          handleImagePanel(3, event);
        } else if (index >= 4 && index <= 7) {
          if (previewPanelRefs.current[7]) {
            handleImagePanel(7, event)
          } else if (previewPanelRefs.current[5]) {
            handleImagePanel(5, event)
          }
        }
      }
    }
  }
  function closeItem(event, index) {
    setSelectedItem({});
    resetImagePanel();
    setActiveItemId("");
  }
  function handleMouseEnter() {
    if (typeof document !== "undefined") {
      document?.activeElement?.blur();
    }
  }

  return (
    <>
      <section className="rx-image-grid">
        <Container fluid>
          <Row className={"grid-row"} style={{ marginTop: "30px", marginBottom: "30px" }}>
            <Col md={12} className="rx-image-grid-header">
              <h3>{heading}</h3>

              {_rawBody ? (
                <div>
                  <BlockContent blocks={_rawBody} />
                </div>
              ) : null}
            </Col>
            {cards &&
              cards.map((card, index) => {
                return (
                  <React.Fragment key={index}>
                    <Col xs={6} md={3} style={{ marginTop: "15px", marginBottom: "15px" }}>
                      <button
                        className={`${activeItemId === card.id ? "rx-image-grid-item active" : "rx-image-grid-item"}`}
                        data-id={card.id}
                        onClick={ev => openItem(ev, index)}
                        onMouseEnter={handleMouseEnter}
                      >
                        <div className="rx-card--overlay-bg"></div>
                        <div className="tag">
                          <span>{card.name}</span>
                        </div>
                        <picture
                          style={
                            card._rawImage.asset.metadata
                              ? {
                                  background: `url(${card._rawImage.asset.metadata.lqip})`,
                                  backgroundSize: "cover"
                                }
                              : undefined
                          }
                        >
                          <source
                            media={"(min-width: 1200px)"}
                            srcSet={
                              urlBuilder
                                .image(card._rawImage)
                                .auto("format")
                                .quality(80)
                                .width(500)
                                .height(500)
                                .format("webp")
                                .url() || undefined
                            }
                          />
                          <source
                            media={"(min-width: 768px)"}
                            srcSet={
                              urlBuilder
                                .image(card._rawImage)
                                .auto("format")
                                .quality(80)
                                .width(500)
                                .height(500)
                                .format("webp")
                                .url() || undefined
                            }
                          />
                          <img
                            width="501"
                            height="466"
                            className="img-fluid secondary-image"
                            src={
                              urlBuilder
                                .image(card._rawImage)
                                .auto("format")
                                .quality(80)
                                .width(500)
                                .height(500)
                                .format("webp")
                                .url() || undefined
                            }
                            loading={"lazy"}
                            alt={card._rawImage.alt as string}
                          />
                        </picture>
                      </button>
                    </Col>

                    {(index + 1) % 2 === 0 ? (
                      <Col
                        ref={el => (previewPanelRefs.current[index] = el)}
                        className="rx-image-grid--panel"
                        xs={12}
                        md={12}
                        hidden={true}
                        data-index={index}
                      >
                        {activeItemId ? (
                          <Row noGutters className="align-items-center">
                            <button className="btn btn-close" onClick={ev => closeItem(ev, index)}>
                              <Close />
                              <Accessibility location={"Close"} />
                            </button>
                            <Col md={6}>
                              <div className="rx-image-grid--image">
                                <picture data-testid="product-image">
                                  <source
                                    media={"(min-width: 1200px)"}
                                    srcSet={
                                      urlBuilder
                                        .image(_rawImage)
                                        .auto("format")
                                        .quality(80)
                                        .width(700)
                                        .height(700)
                                        .format("webp")
                                        .url() || undefined
                                    }
                                  />
                                  <source
                                    media={"(min-width: 768px)"}
                                    srcSet={
                                      urlBuilder
                                        .image(_rawImage)
                                        .auto("format")
                                        .quality(80)
                                        .width(700)
                                        .height(700)
                                        .format("webp")
                                        .url() || undefined
                                    }
                                  />
                                  <img
                                    width="501px"
                                    height="466px"
                                    className="img-fluid secondary-image"
                                    src={
                                      urlBuilder
                                        .image(_rawImage)
                                        .auto("format")
                                        .quality(80)
                                        .width(500)
                                        .height(500)
                                        .format("webp")
                                        .url() || undefined
                                    }
                                    loading={"lazy"}
                                    alt={_rawImage?.alt as string}
                                  />
                                </picture>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="rx-image-grid--content">
                                <h3>{name}</h3>
                                <p>{sport}</p>
                                {_rawBio ? <div>{<BlockContent blocks={_rawBio} />}</div> : null}
                              </div>
                            </Col>
                          </Row>
                        ) : null}
                      </Col>
                    ) : null}
                  </React.Fragment>
                );
              })}
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ImageGrid;
