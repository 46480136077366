import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LinkedInSquare } from "../../images/icons/linkedInSquare";
import { InstagramSquare } from "../../images/icons/instagramSqaure";
import { Location } from "../../images/icons/location";
import Accessibility from "../Accessibility";
import { LocalizedContext } from "../../services/LocalizedContextService";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { useInView } from "react-intersection-observer";

import "./styles.scss";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";

export const ImageTextGrid = props => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });
  const { socialHeadingText } = useContext(LocalizedContext).sanityAccessibility || {};

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [rxListRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: "5px 0px"
  });

  return (
    <section className="rx-list rx-list--trainers" ref={rxListRef}>
      {inView ? (
        <Container fluid>
          <Row>
            <Col md={12}>
              <h3 className="rx-list-title">{props.heading}</h3>

              {props._rawBody ? (
                <div className="rx-list-desc">
                  <BlockContent blocks={props._rawBody} />
                </div>
              ) : null}
            </Col>
            {props.items &&
              props.items.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Col xs={12} md={4} className="rx-card">
                      <div className="rx-card-content" style={{ backgroundColor: `${item.bgColor.value}` }}>
                        {item.sport || item.location ? (
                          <div className="rx-card--header">
                            <h3 className="rx-card-title mb-0">{item.sport}</h3>
                            <div className="rx-card-desc">
                              <Location />
                              {item.location}
                            </div>
                          </div>
                        ) : null}
                        <div className="rx-card--image" role="button" tabIndex={0}>
                          {socialHeadingText && (item.instagram || item.linkedin) ? (
                            <div className="rx-card--overlay">
                              <p>{socialHeadingText}</p>
                              {item.instagram ? (
                                <a
                                  className="rx-card-link rx-card-icon"
                                  href={item.instagram}
                                  rel="noopener noreferrer"
                                >
                                  <InstagramSquare />
                                  <Accessibility location={"instagram"} openNewWindow={true} />
                                </a>
                              ) : null}
                              {item.linkedin ? (
                                <a className="rx-card-link rx-card-icon" href={item.linkedin} rel="noopener noreferrer">
                                  <LinkedInSquare />
                                  <Accessibility location={"linkedin"} openNewWindow={true} />
                                </a>
                              ) : null}
                              <div className="rx-card--overlay-bg"></div>
                            </div>
                          ) : null}
                          <picture
                            style={
                              item._rawImage.asset.metadata
                                ? {
                                    background: `url(${item._rawImage.asset.metadata.lqip})`,
                                    backgroundSize: "cover"
                                  }
                                : undefined
                            }
                          >
                            <source
                              media={"(min-width: 1200px)"}
                              srcSet={
                                urlBuilder
                                  .image(item._rawImage)
                                  .auto("format")
                                  .quality(80)
                                  .width(500)
                                  .height(333)
                                  .format("webp")
                                  .url() || undefined
                              }
                            />
                            <source
                              media={"(min-width: 768px)"}
                              srcSet={
                                urlBuilder
                                  .image(item._rawImage)
                                  .auto("format")
                                  .quality(80)
                                  .width(500)
                                  .height(333)
                                  .format("webp")
                                  .url() || undefined
                              }
                            />
                            <img
                              width="501"
                              height="466"
                              className="img-fluid secondary-image"
                              src={
                                urlBuilder
                                  .image(item._rawImage)
                                  .auto("format")
                                  .quality(80)
                                  .width(500)
                                  .height(333)
                                  .format("webp")
                                  .url() || undefined
                              }
                              loading={"lazy"}
                              alt={item._rawImage.alt as string}
                            />
                          </picture>
                        </div>
                        <div className="rx-card--body">
                          <h3 className="rx-card-title mb-10">{item.name}</h3>
                          <div className="rx-card-desc">
                            {item._rawBio ? <BlockContent blocks={item._rawBio} /> : null}
                          </div>
                        </div>
                        {socialHeadingText && (item.instagram || item.linkedin) ? (
                          <div className="rx-card--footer">
                            <p>{socialHeadingText}</p>
                            {item.instagram ? (
                              <a className="rx-card-link rx-card-icon" href={item.instagram} rel="noopener noreferrer">
                                <InstagramSquare />
                                <Accessibility location={"instagram"} openNewWindow={true} />
                              </a>
                            ) : null}
                            {item.linkedin ? (
                              <a className="rx-card-link rx-card-icon" href={item.linkedin} rel="noopener noreferrer">
                                <LinkedInSquare />
                                <Accessibility location={"linkedin"} openNewWindow={true} />
                              </a>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </React.Fragment>
                );
              })}
          </Row>
        </Container>
      ) : null}
    </section>
  );
};
