import React from "react";

export const InstagramSquare = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 512 512">
      <title></title>
      <g></g>
      <path d="M256 46.059c68.413 0 76.515 0.3 103.42 1.501 25.005 1.1 38.507 5.301 47.51 8.802 11.902 4.601 20.504 10.202 29.406 19.103 9.002 9.002 14.503 17.504 19.204 29.406 3.5 9.002 7.702 22.605 8.801 47.51 1.2 27.006 1.5 35.107 1.5 103.42s-0.3 76.515-1.5 103.42c-1.1 25.005-5.301 38.507-8.801 47.51-4.601 11.902-10.202 20.504-19.103 29.406-9.002 9.002-17.503 14.503-29.406 19.204-9.002 3.5-22.604 7.702-47.51 8.801-27.005 1.2-35.107 1.5-103.42 1.5s-76.515-0.3-103.42-1.5c-25.005-1.1-38.507-5.301-47.51-8.801-11.903-4.601-20.504-10.202-29.406-19.103-9.002-9.002-14.503-17.503-19.204-29.406-3.501-9.002-7.702-22.605-8.801-47.51-1.2-27.005-1.501-35.107-1.501-103.42s0.3-76.515 1.501-103.42c1.1-25.005 5.301-38.507 8.802-47.51 4.601-11.903 10.202-20.504 19.103-29.406 9.002-9.002 17.504-14.503 29.406-19.204 9.002-3.501 22.605-7.702 47.509-8.801 26.905-1.2 35.007-1.501 103.42-1.501zM256-0.050c-69.513 0-78.216 0.3-105.52 1.5-27.206 1.2-45.909 5.601-62.112 11.902-16.904 6.601-31.206 15.303-45.409 29.606-14.303 14.203-23.005 28.505-29.606 45.309-6.301 16.303-10.702 34.907-11.902 62.112-1.2 27.405-1.5 36.107-1.5 105.62s0.3 78.216 1.5 105.52c1.2 27.206 5.601 45.909 11.902 62.112 6.602 16.904 15.303 31.206 29.606 45.409 14.203 14.203 28.506 23.005 45.309 29.506 16.303 6.301 34.907 10.702 62.112 11.902 27.305 1.2 36.007 1.5 105.52 1.5s78.215-0.3 105.52-1.5c27.205-1.2 45.909-5.601 62.112-11.902 16.803-6.502 31.106-15.303 45.309-29.506s23.005-28.505 29.506-45.309c6.301-16.303 10.702-34.907 11.902-62.112 1.2-27.305 1.5-36.007 1.5-105.521s-0.3-78.216-1.5-105.52c-1.2-27.206-5.601-45.909-11.902-62.112-6.301-17.004-15.003-31.306-29.305-45.509-14.203-14.203-28.505-23.005-45.309-29.506-16.303-6.301-34.907-10.702-62.112-11.902-27.406-1.3-36.107-1.601-105.62-1.601v0z"></path>
      <path d="M256 124.475c-72.614 0-131.525 58.911-131.525 131.525s58.911 131.525 131.525 131.525 131.525-58.911 131.525-131.525c0-72.614-58.911-131.525-131.525-131.525zM256 341.317c-47.109 0-85.317-38.207-85.317-85.317s38.208-85.317 85.317-85.317c47.109 0 85.317 38.208 85.317 85.317s-38.207 85.317-85.317 85.317z"></path>
      <path d="M423.433 119.273c0 16.959-13.748 30.706-30.706 30.706s-30.706-13.748-30.706-30.706c0-16.959 13.748-30.706 30.706-30.706s30.706 13.748 30.706 30.706z"></path>
    </svg>
  );
};
