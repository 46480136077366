import React, { FunctionComponent } from "react";

import ImageGrid from "../ImageGrid";
import { ImageTextGrid } from "../ImageTextGrid";

import "./styles.scss";

const Grid: FunctionComponent = props => {
  if (props?.items?.length === 0) return null;

  if (props?.items[0]?.__typename === "SanityTrainer") {
    return <ImageTextGrid {...props} />;
  } else {
    return <ImageGrid {...props} />;
  }
};
export default Grid;
